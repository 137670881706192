<template>
  <div class="">
    <section class="section is-main-section">
      <div class="table-container">
        <b-table
          :data="users"
          :loading="loading"
          striped
          hoverable
          bordered
          paginated
          backend-pagination
          backend-filtering
          backend-sorting
          pagination-size="is-small"
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          @filters-change="filtersChange"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
              <template v-if="column.searchable && !column.numeric" #searchable="props">
                <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Search..."
                  icon="magnify"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ column.render ? column.render(props.row, column.field) : props.row[column.field] }}
              </template>
            </b-table-column>
          </template>

          <b-table-column field="actions" label="Actions" v-slot="props">
            <router-link :to="{ name: 'user', params: { id: props.row.id } }">
              <b-button size="is-small">
                <b-icon icon="pencil" size="is-small" />
              </b-button>
            </router-link>

            <b-button size="is-small" v-if="isAdmin" v-on:click="loginAs(props.row)" title="account switch">
              <b-icon icon="account-switch" size="is-small" />
            </b-button>
          </b-table-column>

          <template slot="bottom-left">
            <router-link class="button is-success" :to="{ name: 'user-add' }"> Add User </router-link>

            <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
              >Refresh data</b-button
            >
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
import { isEqual, pickBy, assign } from 'lodash';
import User from '../models/User';

export default {
  name: 'Users',
  components: {},
  data() {
    return {
      users: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      itemForDelete: null,
      params: {},
      columns: [
        {
          field: 'id',
          label: 'ID',
        },
        {
          field: 'name',
          label: 'Name',
          searchable: true,
        },
        {
          field: 'login',
          label: 'Login',
          searchable: true,
        },
        {
          field: 'email',
          label: 'Email',
          searchable: true,
        },
        {
          field: 'is_admin',
          label: 'Admin',
          render: (row, field) => (row[field] ? 'yes' : 'no'),
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      const offset = (this.page - 1) * this.perPage;

      const params = assign(
        {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit,
          offset,
        },
        this.params
      );

      const res = await this.$http.get('/v1/users', { params });
      this.users = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);
      if (!isEqual(params, this.params)) {
        this.params = params;
        this.fetch();
      }
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
    async deleteItem() {
      await User.delete(this.itemForDelete.id);
      this.fetch();
      this.closeDeleteDialog();
      this.$toast.success('User was deleted');
    },
    showDeleteDialog(item) {
      this.itemForDelete = item;
    },
    closeDeleteDialog() {
      this.itemForDelete = null;
    },
    loginAs(user) {
      this.$auth.setToken({ access_token: user.api_token });
      this.$store.commit('token', this.$auth.getPayload());
      this.$http.get('/v1/profile').then((res) => {
        this.$store.commit('profile', res.data);
        this.$toast.success('Account switch done');
        this.$router.replace({ name: 'organizations' });
      });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
};
</script>
